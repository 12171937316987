import axiosIns from '@/libs/axios'

export default class BankApis {
    SaveBankInfo = async (data, streamFunction) => {
      const formData = new FormData()
      Object.keys(data).forEach(element => formData.append(element, data[element]))
      return await axiosIns.post('document/bank/action', formData, {
        onUploadProgress: e => {
          if (streamFunction) {
            streamFunction(e)
          }
        },
      })
    }

    getDataInfo = async () => await axiosIns.get('document/bank')
}
