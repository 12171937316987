import axiosIns from '@/libs/axios'
import { objectToUrlParam } from '@/libs/helper'

export default class FiatApis {
  getFiatInfo = async data => await axiosIns.get('wallet', data);

  getCurrency = async data => await axiosIns.get(`wallet/currency/price${objectToUrlParam(data)}`);

  saveWithdrawal = async data => await axiosIns.post('withdrawal-ticket-bank-module/store', data)

  bankAccountInfo = async () => await axiosIns.get('deposit-ticket-bank-module/banks', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  saveDeposit = async data => {
    const formData = new FormData()
    Object.keys(data).forEach(element => formData.append(element, data[element]))
    return await axiosIns.post('deposit-ticket-bank-module/store', formData)
  }
}
